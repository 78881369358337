import logo from "assets/images/brands/cmv-logo-lg.png";

export const clientName = "CMV Capitals";
export const developedBy = "CMV Capitals";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "CMV Capitals";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;